var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"wizard-vertical mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":"User cohort","before-change":_vm.validationStep1}},[_c('validation-observer',{ref:"step1Rules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Base event","label-cols-md":"2"}},[_c('div',{staticClass:"event-radio-wrapper"},[_c('b-form-radio',{attrs:{"name":"base-event","value":_vm.REGISTER_EVENT_KIND},model:{value:(_vm.baseEvent),callback:function ($$v) {_vm.baseEvent=$$v},expression:"baseEvent"}},[_vm._v(" Account creation ")])],1),_c('div',{staticClass:"event-radio-wrapper"},[_c('b-form-radio',{attrs:{"name":"base-event","value":"ACCOUNT_ACTIVATION"},model:{value:(_vm.baseEvent),callback:function ($$v) {_vm.baseEvent=$$v},expression:"baseEvent"}},[_vm._v(" Account activated ")])],1),_c('div',{staticClass:"event-radio-wrapper"},[_c('b-form-radio',{attrs:{"name":"base-event","value":_vm.PET_STAGE_CHANGE_EVENT_KIND,"inline":true},model:{value:(_vm.baseEvent),callback:function ($$v) {_vm.baseEvent=$$v},expression:"baseEvent"}},[_vm._v(" Pet stage change from ")]),_c('div',{staticClass:"radio-ext"},[_c('div',{staticClass:"select-wrapper"},[_c('validation-provider',{attrs:{"name":"from-stage","rules":("" + (_vm.baseEvent == _vm.PET_STAGE_CHANGE_EVENT_KIND ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.baseEvent != _vm.PET_STAGE_CHANGE_EVENT_KIND,"options":_vm.petStageOptions,"label":"text"},model:{value:(_vm.petStageFrom),callback:function ($$v) {_vm.petStageFrom=$$v},expression:"petStageFrom"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('label',[_vm._v(" To ")]),_c('div',{staticClass:"select-wrapper"},[_c('validation-provider',{attrs:{"name":"to-stage","rules":("" + (_vm.baseEvent == _vm.PET_STAGE_CHANGE_EVENT_KIND ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.baseEvent != _vm.PET_STAGE_CHANGE_EVENT_KIND,"options":_vm.petStageOptions,"label":"text"},model:{value:(_vm.petStageTo),callback:function ($$v) {_vm.petStageTo=$$v},expression:"petStageTo"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)])],1)])],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Users in the segments","label-for":"cohort-segments","label-cols-md":"2"}},[_c('div',{staticClass:"segment-wrapper"},[_c('v-select',{attrs:{"label":"name","options":_vm.segmentsOptions},on:{"search":_vm.segmentsFetchOptions},model:{value:(_vm.segments),callback:function ($$v) {_vm.segments=$$v},expression:"segments"}})],1)])],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Purchase evaluation","before-change":_vm.validationStep2}},[_c('validation-observer',{ref:"step2Rules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"base product","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Product group 'before' change event","label-for":"cohort-base-product-groups","label-cols-md":"2","description":_vm.baseProductGroupDesc,"state":errors.length > 0 ? false:null}},[_c('div',{staticClass:"product-wrapper"},[_c('v-select',{attrs:{"id":"cohort-base-product-groups","label":"name","options":_vm.productsGroupsOptions},on:{"search":_vm.productsGroupsFetchOptions},model:{value:(_vm.baseProductsGroups),callback:function ($$v) {_vm.baseProductsGroups=$$v},expression:"baseProductsGroups"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Product group 'after' change event","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"","description":_vm.targetProductGroupDesc,"label-for":"cohort-target-product","label-cols-md":"2","state":errors.length > 0 ? false:null}},[_c('div',{staticClass:"product-wrapper"},[_c('v-select',{attrs:{"id":"before","label":"name","options":_vm.productsGroupsOptions},on:{"search":_vm.productsGroupsFetchOptions},model:{value:(_vm.targetProductGroups),callback:function ($$v) {_vm.targetProductGroups=$$v},expression:"targetProductGroups"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Eveluation Period","label-for":"cohort-eval-period","label-cols-md":"2"}},[_c('div',{staticClass:"period-wrapper"},[_c('v-select',{attrs:{"options":_vm.periodOptions,"label":"text"},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}})],1)])],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Journey details","before-change":_vm.validationStep3}},[_c('validation-observer',{ref:"step3Rules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Journey name","label-for":"cohort-name","label-cols-md":"2"}},[_c('div',{staticClass:"cohort-name-wrapper"},[_c('b-form-input',{attrs:{"id":"cohort-name","type":"text","placeholder":"Please journey name"},model:{value:(_vm.cohortName),callback:function ($$v) {_vm.cohortName=$$v},expression:"cohortName"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Start year","label-for":"cohort-start-year","label-cols-md":"2"}},[_c('div',{staticClass:"start-year-wrapper"},[_c('v-select',{attrs:{"options":_vm.yearsOptions},model:{value:(_vm.cohortStartYear),callback:function ($$v) {_vm.cohortStartYear=$$v},expression:"cohortStartYear"}})],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }