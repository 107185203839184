<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="wizard-vertical mb-3"
      @on-complete="formSubmitted"
    >

      <!-- account datails tab -->
      <tab-content
        title="User cohort"
        :before-change="validationStep1"
      >
        <validation-observer
          ref="step1Rules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >

              <b-form-group
                label="Base event"
                label-cols-md="2"
              >

                <div class="event-radio-wrapper">
                  <b-form-radio
                    v-model="baseEvent"
                    name="base-event"
                    :value="REGISTER_EVENT_KIND"
                  >
                    Account creation
                  </b-form-radio>
                </div>

                <div class="event-radio-wrapper">
                  <b-form-radio
                    v-model="baseEvent"
                    name="base-event"
                    value="ACCOUNT_ACTIVATION"
                  >
                    Account activated
                  </b-form-radio>
                </div>

                <div class="event-radio-wrapper">
                  <b-form-radio
                    v-model="baseEvent"
                    name="base-event"
                    :value="PET_STAGE_CHANGE_EVENT_KIND"
                    :inline="true"
                  >
                    Pet stage change from
                  </b-form-radio>
                  <div class="radio-ext">
                    <div class="select-wrapper">
                      <validation-provider
                        v-slot="{ errors }"
                        name="from-stage"
                        :rules="`${baseEvent == PET_STAGE_CHANGE_EVENT_KIND ? 'required' : ''}`"
                      >
                        <v-select
                          v-model="petStageFrom"
                          :disabled="baseEvent != PET_STAGE_CHANGE_EVENT_KIND"
                          :options="petStageOptions"
                          label="text"
                        />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                    <label>
                      To
                    </label>
                    <div class="select-wrapper">
                      <validation-provider
                        v-slot="{ errors }"
                        name="to-stage"
                        :rules="`${baseEvent == PET_STAGE_CHANGE_EVENT_KIND ? 'required' : ''}`"
                      >
                        <v-select
                          v-model="petStageTo"
                          :disabled="baseEvent != PET_STAGE_CHANGE_EVENT_KIND"
                          :options="petStageOptions"
                          label="text"
                        />

                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </div>

                  </div>

                </div>

              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              class="mb-2"
            >
              <b-form-group
                label="Users in the segments"
                label-for="cohort-segments"
                label-cols-md="2"
              >
                <div class="segment-wrapper">
                  <v-select
                    v-model="segments"
                    label="name"
                    :options="segmentsOptions"
                    @search="segmentsFetchOptions"
                  />
                </div>
              </b-form-group>
            </b-col>

          </b-row>
        </validation-observer>

      </tab-content>

      <!-- personal info tab -->
      <tab-content
        title="Purchase evaluation"
        :before-change="validationStep2"
      >
        <validation-observer
          ref="step2Rules"
          tag="form"
        >
          <b-row>

            <b-col
              cols="12"
              class="mb-2"
            >

              <validation-provider
                v-slot="{ errors }"
                name="base product"
                rules="required"
              >
                <b-form-group
                  label="Product group 'before' change event"
                  label-for="cohort-base-product-groups"
                  label-cols-md="2"
                  :description="baseProductGroupDesc"
                  :state="errors.length > 0 ? false:null"
                >
                  <div class="product-wrapper">
                    <v-select
                      id="cohort-base-product-groups"
                      v-model="baseProductsGroups"
                      label="name"
                      :options="productsGroupsOptions"
                      @search="productsGroupsFetchOptions"
                    />

                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false:null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>

              </validation-provider>

            </b-col>

            <b-col
              cols="12"
              class="mb-2"
            >

              <validation-provider
                v-slot="{ errors }"
                name="Product group 'after' change event"
                rules="required"
              >
                <b-form-group
                  label=""
                  :description="targetProductGroupDesc"
                  label-for="cohort-target-product"
                  label-cols-md="2"
                  :state="errors.length > 0 ? false:null"
                >
                  <div class="product-wrapper">
                    <v-select
                      id="before"
                      v-model="targetProductGroups"
                      label="name"
                      :options="productsGroupsOptions"
                      @search="productsGroupsFetchOptions"
                    />

                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false:null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>

              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              class="mb-2"
            >
              <b-form-group
                label="Eveluation Period"
                label-for="cohort-eval-period"
                label-cols-md="2"
              >
                <div class="period-wrapper">
                  <v-select
                    v-model="period"
                    :options="periodOptions"
                    label="text"
                  />

                </div>
              </b-form-group>

            </b-col>

          </b-row>
        </validation-observer>
      </tab-content>

      <!-- address -->
      <tab-content
        title="Journey details"
        :before-change="validationStep3"
      >

        <validation-observer
          ref="step3Rules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >

              <validation-provider
                v-slot="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-group
                  label="Journey name"
                  label-for="cohort-name"
                  label-cols-md="2"
                >
                  <div class="cohort-name-wrapper">
                    <b-form-input
                      id="cohort-name"
                      v-model="cohortName"
                      type="text"
                      placeholder="Please journey name"
                    />

                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false:null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>

              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              class="mb-2"
            >

              <b-form-group
                label="Start year"
                label-for="cohort-start-year"
                label-cols-md="2"
              >
                <div class="start-year-wrapper">
                  <v-select
                    v-model="cohortStartYear"
                    :options="yearsOptions"
                  />
                </div>

              </b-form-group>

            </b-col>
          </b-row>

        </validation-observer>
      </tab-content>

    </form-wizard>

  </div>
</template>

<script>

import {
  defineComponent, ref, watch, computed,
} from '@vue/composition-api'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { FormWizard, TabContent } from 'vue-form-wizard'

import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { debounce } from 'lodash'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormRadioGroup,
  BFormRadio,
  BFormInvalidFeedback,

} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email } from '@validations'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { PET_STAGE_CHANGE_EVENT_KIND, REGISTER_EVENT_KIND, ACCOUNT_ACTIVATION } from '../store/consts'

const { useActions } = createNamespacedHelpers('cohort') // specific module name

export default defineComponent({
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // BFormRadioGroup,
    BFormRadio,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    value: {
      type: Object,
      default() {
        return { name: '' }
      },
      required: false,
    },
  },
  setup(props, context) {
    const currentYear = new Date().getFullYear()
    const years = []
    for (let i = currentYear - 3; i <= currentYear; i += 1) {
      years.push(i)
    }

    const periodOptions = ref([
      { value: 1, text: '1 Month' },
      { value: 2, text: '2 Months' },
      { value: 3, text: '3 Months' },
      { value: 4, text: '4 Months' },
      { value: 5, text: '5 Months' },
      { value: 6, text: '6 Months' },
    ])

    const modleValue = { ...props.value }

    const CUSTOM_EVENT_KIND = 'event_kind:custom'

    const baseEvent = ref(REGISTER_EVENT_KIND)
    const petStageFrom = ref()
    const petStageTo = ref()
    const period = ref(periodOptions.value[2])
    const productsGroupsOptions = ref()
    const baseProductsGroups = ref()
    const targetProductGroups = ref()
    const customEvents = ref('')

    const petStageOptions = [
      { value: '1', text: 'Stage 1' },
      { value: '2', text: 'Stage 2' },
      { value: '3', text: 'Stage 3' },
      { value: '4', text: 'Stage 4' },
    ]

    const yearsOptions = ref(years)
    const segmentsOptions = ref([])

    const cohortName = ref(modleValue.name ?? '')
    const cohortStartYear = ref(modleValue.start_year ?? currentYear)
    const segments = ref(modleValue.segments && modleValue.segments.length > 0 ? modleValue.segments[0] : '')

    function bindValues(newValue) {
      if (newValue) {
        if (newValue.name) {
          cohortName.value = newValue.name
        }
        if (newValue.start_year) {
          if (yearsOptions.value.indexOf(newValue.start_year) === -1) {
            yearsOptions.value = yearsOptions.value.concat([newValue.start_year]).sort()
          }

          cohortStartYear.value = yearsOptions.value[yearsOptions.value.indexOf(newValue.start_year)]
        }
        if (newValue.event_kind) {
          baseEvent.value = newValue.event_kind
        }

        if (newValue.event_kind === PET_STAGE_CHANGE_EVENT_KIND) {
          if (newValue.event_det && newValue.event_det.from_stage) {
            petStageFrom.value = newValue.event_det.from_stage
          }

          if (newValue.event_det && newValue.event_det.to_stage) {
            petStageTo.value = newValue.event_det.to_stage
          }
        }

        if (newValue.event_kind === CUSTOM_EVENT_KIND) {
          if (newValue.event_det && newValue.event_det.events) {
            [customEvents.value] = newValue.event_det.events
          }
        }

        if (newValue.period) {
          period.value = periodOptions.value.find(p => p.value === newValue.period)
        }

        if (newValue.target_products) {
          [targetProductGroups.value] = newValue.target_products
        }

        if (newValue.base_products) {
          [baseProductsGroups.value] = newValue.base_products
        }

        if (newValue.segments) {
          [segments.value] = newValue.segments
        }
      }
    }

    bindValues(props.value)

    watch(() => props.value, newValue => {
      bindValues(newValue)
    }, { immediate: true })

    const save = close => {
      const modle = {
        ...props.value,
        start_year: cohortStartYear.value,
        name: cohortName.value,
        event_kind: baseEvent.value,
        base_products: [baseProductsGroups.value],
        target_products: [targetProductGroups.value],
        period: period.value.value,
      }

      const eventDet = props.value && props.value.event_det ? { ...props.value.event_det } : {}

      if (baseEvent.value === PET_STAGE_CHANGE_EVENT_KIND) {
        eventDet.from_stage = petStageFrom.value
        eventDet.to_stage = petStageTo.value
        modle.event_det = eventDet
      } else if (baseEvent.value === CUSTOM_EVENT_KIND) {
        eventDet.events = [customEvents.value]
        modle.event_det = eventDet
      } else {
        modle.event_det = undefined
      }

      if (segments.value && segments.value.id) {
        modle.segments = [segments.value]
      }
      context.emit('save', { data: modle, close })
    }

    const { searchSegments } = useActions(['searchSegments'])

    const populateSegments = debounce((search, loading) => {
      loading(!segmentsOptions.value || segmentsOptions.value.length)
      searchSegments(search).then(segResults => {
        segmentsOptions.value = segResults
      }).finally(() => loading(false))
    }, 300)

    const segmentsFetchOptions = async (search, loading) => {
      populateSegments(search, loading)
    }

    const { searchProductsGroups } = useActions(['searchProductsGroups'])

    const populateProductGroups = debounce((search, loading) => {
      loading(!productsGroupsOptions.value || productsGroupsOptions.value.length === 0)
      searchProductsGroups(search).then(segResults => {
        productsGroupsOptions.value = segResults
      }).finally(() => loading(false))
    }, 300)

    const productsGroupsFetchOptions = async (search, loading) => {
      populateProductGroups(search, loading)
    }

    const baseProductGroupDesc = computed(() => {
      if (baseEvent.value === PET_STAGE_CHANGE_EVENT_KIND) {
        return 'Product group with purchases made before pet stage change'
      }

      if (baseEvent.value === REGISTER_EVENT_KIND) {
        return 'Product group with purchases made before account creation'
      }

      if (baseEvent.value === ACCOUNT_ACTIVATION) {
        return 'Product group with purchases made before account activated'
      }
      return 'Product group with purchases made before the event'
    })

    const targetProductGroupDesc = computed(() => {
      if (baseEvent.value === PET_STAGE_CHANGE_EVENT_KIND) {
        return 'Product group with purchases made after pet stage change'
      }

      if (baseEvent.value === REGISTER_EVENT_KIND) {
        return 'Product group with purchases made after account creation'
      }

      if (baseEvent.value === ACCOUNT_ACTIVATION) {
        return 'Product group with purchases made after account activated'
      }
      return 'Product group with purchases made after the event'
    })

    return {
      PET_STAGE_CHANGE_EVENT_KIND,
      CUSTOM_EVENT_KIND,
      ACCOUNT_ACTIVATION,
      REGISTER_EVENT_KIND,
      periodOptions,
      baseEvent,
      petStageFrom,
      petStageTo,
      cohortName,
      period,
      petStageOptions,
      save,
      yearsOptions,
      cohortStartYear,
      segmentsOptions,
      segments,
      segmentsFetchOptions,
      populateSegments,
      targetProductGroups,
      baseProductsGroups,
      productsGroupsOptions,
      populateProductGroups,
      productsGroupsFetchOptions,
      customEvents,
      baseProductGroupDesc,
      targetProductGroupDesc,
    }
  },
  data() {
    return { required, email }
  },
  methods: {
    validationStep1() {
      return new Promise((resolve, reject) => {
        this.$refs.step1Rules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationStep2() {
      return new Promise((resolve, reject) => {
        this.$refs.step2Rules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationStep3() {
      return new Promise((resolve, reject) => {
        this.$refs.step2Rules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validateAll() {
      const step1Rules = this.$refs.step1Rules.validate()
      const step2Rules = this.$refs.step2Rules.validate()
      const step3Rules = this.$refs.step3Rules.validate()
      return Promise.all([step1Rules, step2Rules, step3Rules]).then(([first, second, thired]) => first && second && thired)
    },
    formSubmitted() {
      this.validateAll().then(() => {
        this.save(true)
      })
    },
  },
})
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  .event-radio-wrapper {
      padding-bottom: 25px;
  }

  .event-radio-wrapper .radio-ext {
      display: inline-flex;
  }

  .event-radio-wrapper .radio-ext label{
      padding-top: 12px;
      padding-left: 8px;
      padding-right: 8px;
  }

  .event-radio-wrapper .radio-ext .select-wrapper {
      width: 180px;
  }

  .segment-wrapper {
      width: 385px;
  }

  .period-wrapper {
      width: 200px;
  }
  .product-wrapper {
    width: 385px;
  }
  .cohort-name-wrapper {
    width: 385px;
  }

  .start-year-wrapper {
      width: 180px;
  }
</style>
