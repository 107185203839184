<template>
  <CohortAddWizard
    :value="currentCohort"
    @save="saveCohort"
  />
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import store from '@/store'
import CohortAddWizard from '../components/CohortAddWizard.vue'

const { mapActions } = createNamespacedHelpers('cohort')

let isRouteBusy = false

const populateCurrentCohort = (id, next) => {
  if (isRouteBusy) {
    return
  }
  isRouteBusy = true

  store.dispatch('cohort/getCohortForEdit', id).then(item => {
    isRouteBusy = false
    next(vm => vm.setCurrentCohort(item))
  }, () => {
    isRouteBusy = false
  })
}

export default {
  components: {
    CohortAddWizard,
  },

  beforeRouteEnter(to, from, next) {
    populateCurrentCohort(to.params.cohort_id, next)
  },
  beforeRouteUpdate(to, from, next) {
    populateCurrentCohort(to.params.cohort_id, next)
  },
  data() {
    return {
      currentCohort: {},
    }
  },
  methods: {
    setCurrentCohort(item) {
      this.currentCohort = item
    },
    saveCohort({ data, close }) {
      this.updateCohort({ data, id: this.currentCohort.id }).then(() => {
        if (close) {
          this.$router.push({ name: 'purchase-journey' })
        }
      }, () => {
      })
    },
    ...mapActions(['updateCohort']),
  },
}
</script>
